<template>
  <b-tabs>
    <b-tab title="Blocked days">
      <span class="hidden">
        {{ location.availabilityOverrides }}
      </span>
      <LocationAvailabilityOverrides
        v-model="location.availabilityOverrides"
        :location-id="location.id"
      />
    </b-tab>
    <b-tab title="Recurring availability">
      <b-card
        title="Explanation"
      >
        <div>
          Below you can let us know between what times you wish to receive dates by adding your business hours for each
          day that you're open.
          If you want, you can add more time slots to give us more specific preferences
          (This can be useful if you don't want any dates between certain hours). Make sure the time windows don't overlap. <br>
          <strong>From - Until: </strong> Let us know between when we're allowed to make reservations. <br>
          <strong>Max customers: </strong> Optional if you only want a maximum amount of reservations for that time slot. <br>
          <b-button
            variant="secondary"
            size="sm"
          >
            +
          </b-button>
          Click on this to add a time slot for that day. <br>
          <b-button
            variant="warning"
            size="sm"
          >X
          </b-button>
          Click on this to remove the given time slot. <br><br>
          If you have a question, give us a <a
            href="tel:+31103220150"
            class="underline"
          >call</a>
          <br>
          <b>Note:</b> Reservations are moved automatically if they no longer fit within your availability. This can take up to 5 minutes.
        </div>
      </b-card>

      <b-tabs>
        <b-tab
          v-for="deal in getVisibleDeals(location.deals)"
          :key="deal.id"
          :title="deal.name"
        >
          <TimeSlots
            v-model="deal.timeSlots"
            :deals="dealList(deal)"
            :location-id="location.id"
          />
        </b-tab>
      </b-tabs>
    </b-tab>
  </b-tabs>
</template>

<script>

import TimeSlots from '@/components/account/TimeSlots.vue'
import LocationAvailabilityOverrides from '@/components/account/LocationAvailabilityOverrides.vue'
import { mapGetters } from 'vuex'
import { Deal } from '@/_models'

export default {
  components: {
    TimeSlots,
    LocationAvailabilityOverrides,
  },
  computed: {
    ...mapGetters({
      location: 'authentication/location',
    }),
  },
  methods: {
    dealList(deal) {
      return [new Deal(deal.id)]
    },
    getVisibleDeals(deals) {
      return deals.filter(deal => deal.deprecatedAt == null && deal.dealType.active)
    },
  },
}
</script>
