<template>
  <b-card title="Time slots">
    <div class="week-container">
      <div
        v-for="(day, dayIndex) in days"
        :key="dayIndex"
      >
        <div class="row border-1 border-bottom border-gray">
          <div
            class="col-md-3 flex-nowrap col-sm-12 row flex align-items-center"
          >
            <div class="col-6 text-nowrap">
              {{ day }}
            </div>
            <div class="col-6">
              <b-button
                class="remove btn btn-outline-primary"
                @click="addSlot(dayIndex)"
              >
                +
              </b-button>
            </div>
          </div>
          <div class="flex-grow-1 col-md col-sm-12 pb-1">
            <div
              v-for="(slot, slotIndex) in editData"
              :key="slotIndex"
            >
              <div
                v-if="slot.weekday === dayIndex"
                class="d-flex flex-wrap"
                style="gap: 10px"
                :class="{ faulty: slot.faultyReason != null }"
              >
                <div class="p-relative mt-1">
                  <label
                    for="from"
                    class="on-border"
                  >From</label>
                  <b-form-input
                    v-model="slot.parsedFrom"
                    type="time"
                    @input="updateSlot(editData, slotIndex)"
                  />
                </div>
                <div class="p-relative mt-1">
                  <label
                    for="until"
                    class="on-border"
                  >Until</label>
                  <b-form-input
                    v-model="slot.parsedUntil"
                    type="time"
                    @input="updateSlot(editData, slotIndex)"
                  />
                </div>
                <div class="p-relative mt-1">
                  <label
                    for="until"
                    class="on-border"
                  >Max dates</label>
                  <b-input
                    v-model.number="slot.maxDateCount"
                    width="50"
                    @input="updateSlot(editData, slotIndex)"
                  />
                </div>
                <div class="p-relative mt-1">
                  <b-button
                    v-if="slot.updated"
                    class="mr-2"
                    variant="outline-primary"
                    @click="saveSlot(editData, slotIndex)"
                  >
                    <feather-icon
                      v-if="!slot.updating"
                      icon="SaveIcon"
                    />
                    <feather-icon
                      v-if="slot.updating"
                      class="spinner"
                      icon="RefreshCwIcon"
                    />
                  </b-button>
                  <b-button
                    v-if="!slot.updating"
                    variant="danger"
                    @click="removeSlot(editData, slotIndex)"
                  >
                    <feather-icon
                      icon="Trash2Icon"
                    />
                  </b-button>
                </div>
                <div
                  v-if="slot.faultyReason && slot.faultyReason.includes('overlap')"
                  class="faulty-info"
                >
                  Slots can't have any overlap
                </div>
                <div
                  v-if="slot.faultyReason && slot.faultyReason.includes('incorrect')"
                  class="faulty-info"
                >
                  The 'From' should be before the 'Until'
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-card>
</template>

<script>
import { LocationTimeSlot } from '@/_models'
import { BButton } from 'bootstrap-vue'
import { event } from 'vue-gtag'

export default {
  name: 'TimeSlots',
  components: {
    BButton,
  },
  props: {
    allowMax: Boolean,
    value: {
      type: Array,
      default: () => [],
    },
    deals: {
      type: Array,
      default: () => [],
    },
    locationId: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      editData: [],
    }
  },
  watch: {
    allowMax: {
      handler(newValue) {
        if (!newValue) {
          this.editData.forEach(slot => {
            slot.maxDateCount = 0
          })
        }
        this.updateValue()
      },
    },
  },
  mounted() {
    this.parseIntTimeToDate()
  },
  methods: {
    checkOverlapping(slotIndex) {
      const slot = this.editData[slotIndex]
      let faulty = false

      this.editData.forEach((otherSlot, index) => {
        if (index !== slotIndex && otherSlot.weekday === slot.weekday) {
          const start1 = this.parseTimeToMinutes(slot.parsedFrom)
          const end1 = this.parseTimeToMinutes(slot.parsedUntil)
          const start2 = this.parseTimeToMinutes(otherSlot.parsedFrom)
          const end2 = this.parseTimeToMinutes(otherSlot.parsedUntil)
          if (start1 < end2 && start2 < end1) {
            faulty = true
          }
        }
      })

      return faulty
    },
    slotsForDay(data, dayIndex) {
      return data.filter(slot => slot.weekday === dayIndex)
    },
    updateSlot(editData, slotIndex) {
      const slot = this.editData[slotIndex]

      // Adjust times if necessary
      let fromMinutes = this.parseTimeToMinutes(slot.parsedFrom)
      let untilMinutes = this.parseTimeToMinutes(slot.parsedUntil)

      if (fromMinutes < 540) { // Before 9 AM
        slot.parsedFrom = '09:00:00'
        fromMinutes = 540
      }
      if (untilMinutes >= 1380) { // After 11 PM
        slot.parsedUntil = '23:00:00'
        untilMinutes = 1380
      }

      // Check faults
      const faultyReasons = []

      if (fromMinutes >= untilMinutes) {
        faultyReasons.push('incorrect')
      }
      if (this.checkOverlapping(slotIndex)) {
        faultyReasons.push('overlap')
      }

      // Assign faults
      slot.faultyReason = faultyReasons.length > 0 ? faultyReasons : null
      this.$set(slot, 'updated', true)
    },
    addSlot(dayIndex) {
      const timeSlot = new LocationTimeSlot()
      timeSlot.weekday = dayIndex
      timeSlot.parsedFrom = '09:00:00'
      timeSlot.parsedUntil = '23:00:00'
      timeSlot.updated = true
      timeSlot.deals = this.deals

      // First, add the slot
      this.editData.push(timeSlot)

      // Then, get the correct index (new slot is at the last index)
      const newSlotIndex = this.editData.length - 1

      // Now check for overlapping
      const faultyReasons = []
      if (this.checkOverlapping(newSlotIndex)) {
        faultyReasons.push('overlap')
      }
      timeSlot.faultyReason = faultyReasons.length > 0 ? faultyReasons : null

      // Set the faulty reason in the list
      this.$set(this.editData[newSlotIndex], 'faultyReason', timeSlot.faultyReason)
    },
    removeSlot(editData, slotIndex) {
      // eslint-disable-next-line no-alert
      if (window.confirm('Are you sure?')) {
        event('remove-timeslot', {
          day: this.editData[slotIndex].weekday,
          from: this.editData[slotIndex].openFrom,
          until: this.editData[slotIndex].openUntil,
        })

        if (this.editData[slotIndex].id) {
          this.editData[slotIndex].deleteThis()
        }

        this.editData.splice(slotIndex, 1)
      }
    },
    parseIntTimeToDate() {
      this.editData = []
      this.value.forEach(slot => {
        slot.parsedFrom = `${Math.floor(slot.openFrom / 60).toString().padStart(2, '0')}:${(slot.openFrom % 60).toString().padStart(2, '0')}:00`
        slot.parsedUntil = `${Math.floor(slot.openUntil / 60).toString().padStart(2, '0')}:${(slot.openUntil % 60).toString().padStart(2, '0')}:00`
        slot.deals = this.deals
        this.editData.push(slot)
      })
      this.editData.sort((a, b) => ((a.weekday - b.weekday !== 0) ? a.weekday - b.weekday : a.openFrom - b.openFrom))
    },
    saveSlot(editData, slotIndex) {
      if (this.editData[slotIndex].faultyReason && this.editData[slotIndex].faultyReason.includes('overlap')) {
        alert('Cannot save overlapping timeslots')
        return
      }
      if (this.editData[slotIndex].faultyReason && this.editData[slotIndex].faultyReason.includes('incorrect')) {
        alert('Cannot save incorrect timeslots')
        return
      }

      this.$set(this.editData[slotIndex], 'updating', true)

      const slot = this.editData[slotIndex]
      slot.locationId = (slot.locationId) ?? this.locationId
      slot.openFrom = parseInt(slot.parsedFrom.split(':')[0], 10) * 60 + parseInt(slot.parsedFrom.split(':')[1], 10)
      slot.openUntil = parseInt(slot.parsedUntil.split(':')[0], 10) * 60 + parseInt(slot.parsedUntil.split(':')[1], 10)
      slot.save().then(() => {
        this.$set(this.editData[slotIndex], 'updated', false)
        this.$set(this.editData[slotIndex], 'updating', false)
      })

      event('save-timeslot', {
        day: slot.weekday,
        from: slot.openFrom,
        until: slot.openUntil,
      })
    },
    parseTimeToMinutes(time) {
      const [hours, minutes] = time.split(':')
      return parseInt(hours, 10) * 60 + parseInt(minutes, 10)
    },
  },
}
</script>

<style lang="scss" scoped>
.on-border {
  position: absolute;
  top: -8px;
  left: 5px;
  padding: 0 0.5rem;
  background: white;
  z-index: 1;
}

.p-relative {
  position: relative;
}

.faulty {
  color: red;
  border: 1px solid red;
}

.faulty-info {
  color: red;
  font-size: 12px;
  margin-top: -10px;
  text-align: right;
  padding-right: 2px;
}
</style>
